
import { computed, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { notif } from "@/store/stateless/store";
import * as Yup from "yup";


interface login {
  email: string | undefined,
  password: string | undefined
}

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const disabledButton = ref(false)

    const email = ref<string>()
    const password = ref<string>()
    
    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().required().label("Username"),
      password: Yup.string().min(8).required().label("Password"),
    });

    const isPassword = ref(true);

    //Form submit function
    const isClick = ref(false);

    const HandleClick = async() => {
      const values:login = {
        email: email.value,
        password: password.value
      }
      
      disabledButton.value = true

      if(submitButton.value) submitButton.value.setAttribute("data-kt-indicator", "on");

      try {
        
        await store.dispatch(Actions.LOGIN, values)

        const uid = window.localStorage.getItem('uid');
        const user = store.getters.currentUser;
        const localStorageRoute = window.localStorage.getItem("currentRoute");
        const currentRoute = localStorageRoute ? JSON.parse(localStorageRoute) : null;

        notif.simple(
              "Login succcessful",
              "success",
              "You have sucessfully logged in to SKYE. Have a great day!",
              4500,
              "notification-success"
            );

           
          await store.dispatch(Actions.GET_ROUTE_LIST, { type: "misk" });
          window.localStorage.setItem('uid', user.id);

          disabledButton.value = false

          return await router.push({ path: ((currentRoute && uid == user.id) ? currentRoute : ((user.role_id == 6) ? '/dashboard' : '/dashboard')) })
                             .then(() => { router.go(0) })

        
      } catch(e) {
        submitButton.value?.removeAttribute("data-kt-indicator");

        disabledButton.value = false

      }
      submitButton.value?.removeAttribute("data-kt-indicator");


    }

    const loginError = computed(() => {
      return store.getters.getLoginError;
    });

    const resetError = () => {
      store.commit(Mutations.SET_LOGIN_ERROR, "")
    }

    return {
      HandleClick,
      resetError,
      isClick,
      loginError,
      login,
      email,
      password,
      submitButton,
      isPassword,
      disabledButton
    };
  },
});
